const animateValue = (obj, start = 0, end = null, duration = 2000) => {
   if (obj) {
      // save starting text for later (and as a fallback text if JS not running and/or google)
      let textStarting = obj.innerHTML;

      // remove non-numeric from starting text if not specified
      end = end || parseInt(textStarting.replace(/\D/g, ""));

      let range = end - start;

      // no timer shorter than 50ms (not really visible any way)
      let minTimer = 50;

      // calc step time to show all interediate values
      let stepTime = Math.abs(Math.floor(duration / range));

      // never go below minTimer
      stepTime = Math.max(stepTime, minTimer);

      // get current time and calculate desired end time
      let startTime = new Date().getTime();
      let endTime = startTime + duration;
      let timer;

      const run = () => {
         let now = new Date().getTime();
         let remaining = Math.max((endTime - now) / duration, 0);
         let value = Math.round(end - remaining * range);
         // replace numeric digits only in the original string
         obj.innerHTML = textStarting.replace(/([0-9]+)/g, value);
         if (value == end) {
            clearInterval(timer);
         }
      };

      timer = setInterval(run, stepTime);
      run();
   }
};

window.addEventListener("load", () => {
   if (!!window.IntersectionObserver) {
      let observer = new IntersectionObserver(
         (entries, observer) => {
            entries.forEach(entry => {
               if (entry.isIntersecting) {
                  //   entry.target.innerHTML = entry.target.dataset.src;
                  animateValue(entry.target);
                  observer.unobserve(entry.target);
               }
            });
         },
         { rootMargin: "0px 0px -200px 0px" }
      );

      // Init view of element
      document.querySelectorAll(".count-this").forEach(counter => {
         observer.observe(counter);
      });
   }
});
